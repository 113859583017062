import React from "react"
import PageLayout from "../layouts/page"
import PageTitle from "../components/PageTitle"
import SEO from "../components/seo"


const NotFound = () => {
  return (
  <PageLayout>
      <SEO title="Realizacje stron internetowych" />
      <PageTitle title="Błąd 404">Nie znaleziono podanej strony :(</PageTitle>
  </PageLayout>
  )
} 




export default NotFound;
